var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('iq-card',[_c('div',{staticClass:"iq-card-header d-flex justify-content-between"},[_c('div',{staticClass:"iq-header-title"},[_c('h4',{staticClass:"pt-1"},[_vm._v(" Etablissement : "),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.user.institution.name))])])])]),_c('div',{staticClass:"iq-card-body"},[_c('b-card',{staticClass:"mb-2",staticStyle:{"border":"1px solid"},attrs:{"border-variant":"primary","header-bg-variant":"primary","header-text-variant":"white","align":"center","header":"Management","id":"1"}},[_c('AmChart',{attrs:{"element":'home-chart-' + 1,"type":"radial-pie","option":[
            {
              country: 'Réponse',
              value: Math.random() * (100 - 10) + 10,
            },
            {
              country: 'Sans réponse',
              value: Math.random() * (100 - 10) + 10,
            },
            {
              country: 'Total',
              value: Math.random() * (100 - 10) + 10,
            } ],"height":280}})],1),_c('b-card',{staticClass:"mb-2",staticStyle:{"border":"1px solid"},attrs:{"border-variant":"primary","header-bg-variant":"primary","header-text-variant":"white","align":"center","header":"Gestion des médicaments","id":"2"}},[_c('AmChart',{attrs:{"element":'home-chart-' + 2,"type":"radial-pie","option":[
            {
              country: 'Réponse',
              value: Math.random() * (100 - 10) + 10,
            },
            {
              country: 'Sans réponse',
              value: Math.random() * (100 - 10) + 10,
            },
            {
              country: 'Total',
              value: Math.random() * (100 - 10) + 10,
            } ],"height":280}})],1),_c('b-card',{staticClass:"mb-2",staticStyle:{"border":"1px solid"},attrs:{"border-variant":"primary","header-bg-variant":"primary","header-text-variant":"white","align":"center","header":"Gestions des résidents","id":"3"}},[_c('AmChart',{attrs:{"element":'home-chart-' + 3,"type":"radial-pie","option":[
            {
              country: 'Réponse',
              value: Math.random() * (100 - 10) + 10,
            },
            {
              country: 'Sans réponse',
              value: Math.random() * (100 - 10) + 10,
            },
            {
              country: 'Total',
              value: Math.random() * (100 - 10) + 10,
            } ],"height":280}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }